i.pi.pi-eye,
i.pi.pi-eye-slash {
  color: var(--primary-color) !important;
}

.submit-btn {
  margin-top: 2rem !important;
}
.submit-btn,
.submit-btn:hover,
.submit-btn:active {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  font-weight: 600 !important;
}

.p-field {
  margin-bottom: 1.5rem;
}
.p-field > input:focus,
#password > input:focus {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 0.2rem var(--shadow-color) !important;
}

.p-field > input:hover,
#password > input:hover {
  border-color: var(--primary-color) !important;
}

.p-checkbox-box {
}
