.datatable-card {
  z-index: 5;
}

.p-datatable {
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: center !important;
  background-color: #000 !important;
  border: 1px 0px solid #707070 !important;
  color: #fff !important;
  padding: .7rem !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  text-align: center !important;
    padding: 0.6rem 1rem !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
}
.p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background: rgb(250, 250, 250);
}

.p-datatable.p-datatable-hoverable-rows
  .p-datatable-tbody
  > tr:not(.p-highlight):hover,
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: none !important;
  color: #000 !important;
}

.p-dialog.p-component.p-confirm-dialog {
  background: rgb(255, 255, 255);
  margin: 0px;
  position: fixed;
  /* left: 292.688px; */
  border-radius: 13px;
  /* top: 407.5px; */
  padding: 3rem;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.p-dialog.p-component.p-confirm-dialog > .p-dialog-content {
  padding: 0;
  font-size: 24px;
  font-weight: bold;
  /* margin-right: 10rem; */
  margin-bottom: 1rem;
}

.p-dialog.p-component.p-confirm-dialog > .p-dialog-footer {
  padding: 0;
}

.p-dialog.p-component.p-confirm-dialog > .p-dialog-header {
  display: none;
}

.p-dialog.p-component.p-confirm-dialog
  > .p-dialog-footer
  > .p-confirm-dialog-reject {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  min-width: 8rem;
}

.p-dialog.p-component.p-confirm-dialog
  > .p-dialog-footer
  > .p-confirm-dialog-accept {
  width: 8rem;
}

.p-dialog.p-component.p-confirm-dialog
  > .p-dialog-footer
  > .p-button.p-component.p-confirm-dialog-accept {
  background-color: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
}

.p-dialog.p-component.p-confirm-dialog
  > .p-dialog-footer
  > .p-button.p-component.p-confirm-dialog-accept.p-button-danger {
  background-color: #d32f2f;
  color: #fff;
  border-color: #d32f2f;
}

.p-selectable-row {
  cursor: default !important;
}

.p-datatable-scrollable-header-box{
  margin: 0 !important;
}

.p-paginator * {
  font-size: 12px !important;
}